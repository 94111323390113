import { useApolloClient } from '@apollo/client';
import magnifyingGlass from '@images/icons/magnifyingGlass.svg';
import { AsyncSelect } from '@moneyforward/cloud-react-ui';
import { useController } from 'react-hook-form';

import { FormError } from '@/components/ui/FormError';
import { Image } from '@/components/ui/Image';
import {
  CurrentOfficeTenantUsersDocument,
  CurrentOfficeTenantUsersQuery,
  CurrentOfficeTenantUsersQueryVariables,
  useCurrentOfficeTenantUsersQuery,
} from '@/graphql';

export const TenantUserInput = (): JSX.Element => {
  const { query } = useApolloClient();
  const {
    field,
    fieldState: { error },
  } = useController({ name: 'tenantUser' });
  const { data: users } = useCurrentOfficeTenantUsersQuery({
    variables: {
      page: 1,
      perPage: 50,
    },
  });

  const parseOptions = (users: CurrentOfficeTenantUsersQuery | undefined) => {
    if (!users?.currentOffice.tenantUsers) return [];
    return users.currentOffice.tenantUsers.map((user) => ({
      label: `${user.displayName} ${user.email}`,
      value: user.uid,
    }));
  };

  const handleSearch = async (input: string) => {
    const { data } = await query<
      CurrentOfficeTenantUsersQuery,
      CurrentOfficeTenantUsersQueryVariables
    >({
      query: CurrentOfficeTenantUsersDocument,
      variables: {
        displayName: input,
      },
    });

    return (data.currentOffice.tenantUsers ?? []).map<{
      label: string;
      value: string;
    }>((tenantUser) => ({
      label: `${tenantUser.displayName} ${tenantUser.email}`,
      value: tenantUser.uid,
    }));
  };

  return (
    <>
      <AsyncSelect
        {...field}
        aria-label="tenantUser"
        instanceId="tenantUserSelect"
        placeholder="表示名でユーザーを検索"
        noOptionsMessage={({ inputValue }) =>
          inputValue ? 'ユーザーが見つかりませんでした' : null
        }
        loadingMessage={({ inputValue }) =>
          inputValue ? '検索しています' : null
        }
        defaultOptions={parseOptions(users)}
        onInputChange={handleSearch}
        indicatorImage={<Image src={magnifyingGlass} width={12} height={12} />}
      />
      {error && <FormError>ユーザーを指定してください</FormError>}
    </>
  );
};
