import { useFormContext } from 'react-hook-form';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError/FormError';
import { LastNameData } from './schema';

export const LastNameInput = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<LastNameData>();

  return (
    <div>
      <TextField
        aria-label="lastName"
        {...register('lastName')}
        placeholder="例）山田"
      />
      {errors.lastName && <FormError>{errors.lastName.message}</FormError>}
    </div>
  );
};
