import { useFormContext } from 'react-hook-form';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError/FormError';
import { FirstNameData } from './schema';

export const FirstNameInput = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FirstNameData>();

  return (
    <div>
      <TextField
        aria-label="firstName"
        {...register('firstName')}
        placeholder="例）太郎"
      />
      {errors.firstName && <FormError>{errors.firstName.message}</FormError>}
    </div>
  );
};
