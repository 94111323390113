import { Select } from '@moneyforward/cloud-react-ui';
import { useCallback, useMemo } from 'react';
import { useFormContext, Controller, FieldArrayWithId } from 'react-hook-form';
import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { Radio } from '@/components/new/Radio';
import { Text } from '@/components/new/Text';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { Image } from '@/components/ui/Image';
import { RegistrationTable } from '@/components/ui/RegistrationTable';
import { RegistrationTableRow } from '@/components/ui/RegistrationTableRow';
import { BeneficialOwnerTypeOptions } from '@/lib/beneficialOwnerType';
import { convertToNationalityOptions } from '@/lib/nationality';
import { PrefectureSelectOptions } from '@/lib/prefecture';
import { useApiError } from '@/hooks/useApiError';
import {
  BeneficialOwnerClassificationEnum,
  useAddressesLazyQuery,
  BeneficialOwnerTypeEnum,
  useNationalitiesQuery,
} from '@/graphql';
import { InputGroup } from '../inputs/InputGroup';
import { RegistrationBeneficialOwnersData } from './schema';
import closeIcon from '@/assets/images/icons/close.svg';

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
`;

const RemoveButton = styled.button`
  background: transparent;
  border: none;
  color: unset;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`;

const AddressWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 6px;
`;

const TextBox = styled.div`
  margin-bottom: 8px;
`;

const RadioWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

type Props = {
  removeBeneficialOwner?: VoidFunction;
  index: number;
  item: FieldArrayWithId<
    RegistrationBeneficialOwnersData,
    'beneficialOwners',
    'id'
  >;
};
export const RegistrationBeneficialOwner = ({
  removeBeneficialOwner,
  index,
  item,
}: Props): JSX.Element => {
  const {
    formState: { errors },
    control,
    getValues,
    register,
    trigger,
    setValue,
    watch,
  } = useFormContext<RegistrationBeneficialOwnersData>();
  const { handleQueryError } = useApiError();
  const [search] = useAddressesLazyQuery({
    onError: handleQueryError,
  });

  const { data } = useNationalitiesQuery({
    onError: handleQueryError,
  });

  const nationalities = data?.nationalities ?? [];

  const nationalityOptions = useMemo(
    () => convertToNationalityOptions(nationalities),
    [nationalities]
  );

  const handleAddressSearch = useCallback(async () => {
    const valid = await trigger(`beneficialOwners.${index}.postalCode`);

    if (!valid) return;

    const postalCode = getValues(`beneficialOwners.${index}.postalCode`);
    const res = await search({ variables: { postcode: postalCode } });
    if (res.data && res.data.addresses.length > 0) {
      const address = res.data.addresses[0];

      setValue(
        `beneficialOwners.${index}.prefectureCode`,
        address.prefectureCode
      );
      setValue(`beneficialOwners.${index}.city`, address.city);
      setValue(
        `beneficialOwners.${index}.street`,
        address.town + (address.street ?? '')
      );
      setValue(`beneficialOwners.${index}.buildingName`, '');
    }
  }, [getValues(`beneficialOwners.${index}.postalCode`)]);

  const isClassificationIndividual =
    watch(`beneficialOwners.${index}.classification`) ===
    BeneficialOwnerClassificationEnum.Individual;

  if (!isClassificationIndividual) {
    setValue(`beneficialOwners.${index}.birthDate`, null);
  }

  const beneficialOwnerTypeOptions = isClassificationIndividual
    ? BeneficialOwnerTypeOptions
    : BeneficialOwnerTypeOptions.filter(
        (option) => option.value !== BeneficialOwnerTypeEnum.Representative
      );

  return (
    <RegistrationTable
      aria-label={`beneficialOwner-${index}`}
      header={
        <HeaderWrapper>
          <div>実質的支配者情報</div>
          {removeBeneficialOwner && (
            <RemoveButton
              aria-label={`removeBeneficialOwner-${index}`}
              type="button"
              onClick={removeBeneficialOwner}
            >
              削除する
              <Image src={closeIcon} width={12} height={12} />
            </RemoveButton>
          )}
        </HeaderWrapper>
      }
    >
      <RegistrationTableRow title="区分">
        <RadioWrapper>
          <Radio
            {...register(`beneficialOwners.${index}.classification`)}
            value={BeneficialOwnerClassificationEnum.Individual}
            defaultChecked={
              item.classification ===
              BeneficialOwnerClassificationEnum.Individual
            }
          >
            個人
          </Radio>
          <Radio
            {...register(`beneficialOwners.${index}.classification`)}
            value={BeneficialOwnerClassificationEnum.Corporate}
            defaultChecked={
              item.classification ===
              BeneficialOwnerClassificationEnum.Corporate
            }
          >
            上場会社・独立行政法人など
          </Radio>
        </RadioWrapper>
        {errors.beneficialOwners?.[index]?.classification && (
          <FormError>
            {errors.beneficialOwners?.[index]?.classification?.message}
          </FormError>
        )}
      </RegistrationTableRow>
      <RegistrationTableRow title="法人との関係性">
        <Controller
          control={control}
          name={`beneficialOwners.${index}.beneficialOwnerType`}
          render={({ field }) => (
            <Select
              {...field}
              aria-label={`beneficialOwnerType-${index}`}
              instanceId={`beneficialOwnerTypeSelect-${index}`}
              options={beneficialOwnerTypeOptions}
              value={beneficialOwnerTypeOptions.find(
                (option) => option.value === field.value
              )}
              onChange={(e) => field.onChange(e?.value ?? '')}
              placeholder="選択してください"
            />
          )}
        />
        {errors.beneficialOwners?.[index]?.beneficialOwnerType && (
          <FormError>
            {errors.beneficialOwners?.[index]?.beneficialOwnerType?.message}
          </FormError>
        )}
      </RegistrationTableRow>
      <RegistrationTableRow
        title={isClassificationIndividual ? '氏名' : '法人名'}
      >
        <TextField
          aria-label={`name-${index}`}
          {...register(`beneficialOwners.${index}.name`)}
          placeholder="例）山田 太郎"
        />
        {errors.beneficialOwners?.[index]?.name && (
          <FormError>
            {errors.beneficialOwners?.[index]?.name?.message}
          </FormError>
        )}
      </RegistrationTableRow>
      <RegistrationTableRow title="フリガナ">
        <TextField
          aria-label={`nameKana-${index}`}
          {...register(`beneficialOwners.${index}.nameKana`)}
          placeholder="例）ヤマダ タロウ"
        />
        {errors.beneficialOwners?.[index]?.nameKana && (
          <FormError>
            {errors.beneficialOwners?.[index]?.nameKana?.message}
          </FormError>
        )}
      </RegistrationTableRow>
      <RegistrationTableRow title="英語表記">
        <TextField
          aria-label={`nameEn-${index}`}
          {...register(`beneficialOwners.${index}.nameEn`)}
          placeholder=""
        />
        {errors.beneficialOwners?.[index]?.nameEn && (
          <FormError>
            {errors.beneficialOwners?.[index]?.nameEn?.message}
          </FormError>
        )}
      </RegistrationTableRow>
      {isClassificationIndividual && (
        <RegistrationTableRow title="生年月日">
          <TextField
            aria-label={`birthDate-${index}`}
            {...register(`beneficialOwners.${index}.birthDate`)}
            placeholder="例）1960/06/06"
          />
          {errors.beneficialOwners?.[index]?.birthDate && (
            <FormError>
              {errors.beneficialOwners?.[index]?.birthDate?.message}
            </FormError>
          )}
        </RegistrationTableRow>
      )}

      <RegistrationTableRow title="住所">
        <TextBox>
          <Text size="small">
            実質的支配者が個人の方の場合は自宅住所を入力してください。国・地方公共団体・上場企業の場合は本店又は主たる事務所の所在地を入力してください。
          </Text>
        </TextBox>
        <AddressWrapper>
          <InputGroup>
            <div>
              <TextField
                aria-label={`postalCode-${index}`}
                {...register(`beneficialOwners.${index}.postalCode`)}
                placeholder="郵便番号"
              />
              {errors.beneficialOwners?.[index]?.postalCode && (
                <FormError>
                  {errors.beneficialOwners?.[index]?.postalCode?.message}
                </FormError>
              )}
            </div>
            <div>
              <Button
                aria-label={`searchAddress-${index}`}
                type="button"
                variant="outline"
                onClick={handleAddressSearch}
              >
                郵便番号から住所を表示
              </Button>
            </div>
          </InputGroup>
          <InputGroup>
            <div>
              <Controller
                control={control}
                name={`beneficialOwners.${index}.prefectureCode`}
                render={({ field }) => (
                  <Select
                    {...field}
                    aria-label={`prefectureCode-${index}`}
                    instanceId={`prefectureCodeSelect-${index}`}
                    options={PrefectureSelectOptions}
                    value={PrefectureSelectOptions.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(e) => field.onChange(e?.value ?? '')}
                    placeholder="都道府県"
                  />
                )}
              />
              {errors.beneficialOwners?.[index]?.prefectureCode && (
                <FormError>
                  {errors.beneficialOwners?.[index]?.prefectureCode?.message}
                </FormError>
              )}
            </div>
            <div>
              <TextField
                aria-label={`city-${index}`}
                {...register(`beneficialOwners.${index}.city`)}
                placeholder="市区町村"
              />
              {errors.beneficialOwners?.[index]?.city && (
                <FormError>
                  {errors.beneficialOwners?.[index]?.city?.message}
                </FormError>
              )}
            </div>
          </InputGroup>
          <div>
            <TextField
              aria-label={`street-${index}`}
              {...register(`beneficialOwners.${index}.street`)}
              placeholder="町名・番地"
            />
            {errors.beneficialOwners?.[index]?.street && (
              <FormError>
                {errors.beneficialOwners?.[index]?.street?.message}
              </FormError>
            )}
          </div>
          <div>
            <TextField
              aria-label={`buildingName-${index}`}
              {...register(`beneficialOwners.${index}.buildingName`)}
              placeholder="建物名・部屋番号（任意）"
            />
            {errors.beneficialOwners?.[index]?.buildingName && (
              <FormError>
                {errors.beneficialOwners?.[index]?.buildingName?.message}
              </FormError>
            )}
          </div>
        </AddressWrapper>
      </RegistrationTableRow>
      <RegistrationTableRow title="国籍">
        <Controller
          control={control}
          name={`beneficialOwners.${index}.nationalityId`}
          render={({ field }) => (
            <Select
              {...field}
              aria-label={`nationalityId-${index}`}
              instanceId={`nationalityId-${index}`}
              placeholder="選択してください"
              options={nationalityOptions}
              value={nationalityOptions.find(
                (option) => option.value === field.value
              )}
              onChange={(e) => field.onChange(e?.value ?? '')}
            />
          )}
        />
        {errors.beneficialOwners?.[index]?.nationalityId && (
          <FormError>
            {errors.beneficialOwners?.[index]?.nationalityId?.message}
          </FormError>
        )}
      </RegistrationTableRow>
    </RegistrationTable>
  );
};
