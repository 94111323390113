import { Select } from '@moneyforward/cloud-react-ui';
import { forwardRef, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { convertToNationalityOptions } from '@/lib/nationality';
import { useApiError } from '@/hooks/useApiError';
import { useNationalitiesQuery } from '@/graphql';
import { FormError } from '../../FormError';
import { NationalityIdSchemaData } from './schema';

export const NationalityInput = forwardRef<HTMLInputElement>((_, ref) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<NationalityIdSchemaData>();

  const { handleQueryError } = useApiError();

  const { data } = useNationalitiesQuery({
    onError: handleQueryError,
  });

  const nationalities = data?.nationalities ?? [];

  const nationalityOptions = useMemo(
    () => convertToNationalityOptions(nationalities),
    [nationalities]
  );

  return (
    <>
      <Controller
        control={control}
        name={'nationalityId'}
        render={({ field }) => (
          <Select
            {...field}
            ref={ref}
            aria-label={'国籍'}
            instanceId={'nationalityId'}
            placeholder="選択してください"
            options={nationalityOptions}
            value={nationalityOptions.find(
              (option) => option.value === field.value
            )}
            onChange={(e) => field.onChange(e?.value ?? '')}
          />
        )}
      />
      {errors.nationalityId && (
        <FormError>{errors.nationalityId.message}</FormError>
      )}
    </>
  );
});

NationalityInput.displayName = 'NationalityInput';
