import { Select } from '@moneyforward/cloud-react-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { FormError } from '@/components/ui/FormError';
import { ResidentialStatusSelectOptions } from '@/lib/residentialStatus';
import { ResidentialStatusSchemaData } from './schema';

export const ResidentialStatusInput = (): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext<ResidentialStatusSchemaData>();

  return (
    <>
      <Controller
        control={control}
        name="residentialStatus"
        render={({ field }) => (
          <Select
            {...field}
            aria-label="在留資格"
            instanceId="residentialStatus"
            placeholder="選択してください"
            options={ResidentialStatusSelectOptions}
            value={ResidentialStatusSelectOptions.find(
              (option) => option.value === field.value
            )}
            onChange={(e) => field.onChange(e?.value ?? '')}
          />
        )}
      />
      {errors.residentialStatus && (
        <FormError>{errors.residentialStatus.message}</FormError>
      )}
    </>
  );
};
